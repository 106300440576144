.App {
  text-align: center;
  color: white;
  background-color: white;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.token {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  display: inline-block;
}

.loading {
  background-color: #ffffff;
  margin: 'auto';
  text-align:'center';
  padding: "150px 0";
}

h1, h2, h3 {
  color: white;
  font-family: 'Major Mono Display', monospace;
}

body {
  padding-bottom: 20px;
  overflow-x: hidden;
}

.gridTable {
  position: fixed;
  height: 80vmin;
  width: 80vmin;
  top: 50%;
  left: 50%;
  border-spacing: 0px;
  border-collapse: collapse;
  margin-top: -40vmin;
  margin-left: -40vmin;
  margin-right: auto;
  margin-bottom: auto;
}

td {
  padding: 0px;
}

.cell {

  height: 100%;
  width: 100%;
  border-radius: 0%;
  padding: 0px;
  border: 0px solid;
  margin: 0%;
  cursor: zoom-in;
  background-position:center center;
  background-repeat:no-repeat;
  background-size:cover;
}

.cell.selected {

  height: 100%;
  width: 100%;
  border-radius: 0%;
  padding: 0px;
  border: 0px solid;
  margin: 0% auto;
  cursor:zoom-out;
  background-position:center center;
  background-repeat:no-repeat;
  background-size:cover;
}

/*
.cell:hover {
  border-radius: 0%;
  padding: 0px;
  border: 1px solid #FFFFFF;
}*/


.alert {
   width:80%; margin-left: 10%;
}

.center {
  margin: auto;
  text-align: center;
  /*
  margin: auto;
  width: 50%;
  border: 3x solid green;
  padding: 4px;
  text-align: center;
  */
}

.bg-light { background-color: transparent!important; }


@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes detail {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.fade-in-text {
  font-family: monospace;
  font-size: 1.5vmin;
  color: #AAAAAA;
  word-break: break-word;
  animation: fadeIn 2s;
}

.fade-in-gurrellia-text {
  font-family: monospace;
  font-size: 4vmin;
  color: #FFFFFF;
  word-break: break-word;
  animation: fadeIn 2s;
}

.fade-in-gurrellia-text-big {
  font-family: monospace;
  font-size: 6vmin;
  color: #FFFFFF;
  word-break: break-word;
  animation: fadeIn 2s;
}

.cell {
	padding: 2vmin;
	animation-name: fadeIn;
	animation-duration: 2s;
}

.cell.selected {
	animation-name: fadeIn;
	animation-duration: 2s;
}